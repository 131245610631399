import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="footer footer-center p-3 bg-neutral text-primary-content">
        <aside>
          <a href="/cv">
            <img
              alt="CV"
              width={60}
              height={60}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADsklEQVR4nO2dPWtUQRSGj2AsbMQiSDQ2gtjZCaKlnaC12Cg2JsS/oJW/RES0CBZ+IYKI+J1EsBb8AMFCBU0igoiPDLliXPaLO2dmTu6cpw2Zed/z7N7c7N7dK+I4zgYB2AvMA8ukJ+xxDdhVurdlGV/IzyeX0l/IPOW4mv8haJxMh6lBfJWNDrAFOAc8A1ZRRtLn1ybM4CkwF2aTOn9vmZ3ASxIi6TukZAmYSt1h/TMjqYxAhh6pWQQmJEORcJhKjqTvkYNZyVAk/M1Yz02N08bSQiR+vWngVs+yT3TSDt94pWfTaaV1/0Njzdz7Abt7ll3WWLfI4FKt2/UenSlCR3qMvSHx7ErYIRzvo4idj2aZVsFacCOFlEZGOBGJInY+moVaBesaEjkfNdoG6xoSOR81UgYDJoHn5CP8TzVZuncUqYMBm4EZ4DHwDX3Cmo+As2EvK71bYzZYrb3NBqu1t9lgtfY2G6zW3maD1drbbLBae5sNVmtvs8Fq7W0pGHC8ufrwbXPVx/4U+1jrbS4YsLXP26U0Vz7eA45o7melt9lgwGWG8xM43LXeJoOxdg3wb0ZzXWvPZl8X0g/gJOPxThRxIfFC3ogiLmQAwL4xhVwSRVzI8Dexfowh5Ioo4kIGAJwY8xnyShRxIQMAdgCvR8gIZ2FnRBEXMgTgwAghD0UZFzIEYBOwMETIRVHGhYwA2AN86CPjPbBdlHEhYwDc6SPkriTAhYyBCzH0SGHtGt3PA17xne5qb3PBWPuM42ngI4MJPzul+WnY0r3NBQO2ARdGiOgn5nz4XYX9XchfgGORX7sRDm1HJQIX0gAcAn4RT1jjoLTEhTQAD9DjvrTEhTQA3xWFrEpLXIgxXIgxXIgxXIgxXIgxXIgxXIgxXIgxqhNCYaRQ72hSBaMwUqh3NKmCURgp1DuaVMEojBTqHY3ZYLX2Nhus1t5mg9Xa22ywWnubDVZrb7PBau2dKhiZEGO9o0kVjEyIsd7RpApGJsRY72hSBSMTYqx3NGaD1drbbLBae5sNVmtvs8Fq7W02WK29zQartbfZYLX2Nhus1t5mg9Xa22ywWnu3DdY1JHI+arQN1jUkcj5qtA3WNSRyPmq0DdY1JHI+amTfcIPhQozhQozhQoxRQshyz57qX4W0USl1++7eG9yHuxRUL4U1GbdL3OB+buxzQ2cmh5DwBWJLPuuRvAAmkgtppEwBi6MzVctCmFEWGeukTACzzT1rV0pPwAArzSxmsj0zHMdxHEf+8QdLkhihfaqXvAAAAABJRU5ErkJggg=="
            ></img>
          </a>
        </aside>
        <nav>
          <div className="grid grid-flow-col gap-4">
            <a href="mailto:alex090xa@gmail.com">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 4l-8 5l-8-5V6l8 5l8-5z"
                />
              </svg>
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://discordapp.com/users/deadkill1600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02M8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12m6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12"
                />
              </svg>
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://github.com/PaulAlv99"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  d="M12 2A10 10 0 0 0 2 12c0 4.42 2.87 8.17 6.84 9.5c.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34c-.46-1.16-1.11-1.47-1.11-1.47c-.91-.62.07-.6.07-.6c1 .07 1.53 1.03 1.53 1.03c.87 1.52 2.34 1.07 2.91.83c.09-.65.35-1.09.63-1.34c-2.22-.25-4.55-1.11-4.55-4.92c0-1.11.38-2 1.03-2.71c-.1-.25-.45-1.29.1-2.64c0 0 .84-.27 2.75 1.02c.79-.22 1.65-.33 2.5-.33s1.71.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.35.2 2.39.1 2.64c.65.71 1.03 1.6 1.03 2.71c0 3.82-2.34 4.66-4.57 4.91c.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0 0 12 2"
                />
              </svg>
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.linkedin.com/in/paulo-alves-a30993151/"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93zM6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37z"
                />
              </svg>
            </a>
          </div>
        </nav>
      </footer>
      <footer className="footer footer-center p-4 bg-base-300 text-base-content">
        <aside>
          <p>Copyright © 2024 - All right reserved by Paulo Alves</p>
        </aside>
      </footer>
    </>
  );
};

export default Footer;
