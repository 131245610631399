const publicacoes = [
  {
    id: 0,
    title: "Welcome to my blog",
    date: new Date("2024-07-07"),
    timeRead: 1,
    description:
      "I'm thrilled to have you here. This blog is a space where I share my thoughts, ideas, and experiences on a variety of topics that I'm passionate about.",
    dotsContentCovered: ["Personal Stories", "Tech", "Travel"],
    pathPost: "/blog/0",
    fullText:
      "I'm thrilled to have you here. This blog is a space where I share my thoughts, ideas, and experiences on a variety of topics that I'm passionate about. Whether you're here for the latest tips, insightful articles, or just a bit of inspiration, I hope you'll find something that resonates with you",
  },
];

export default publicacoes;
