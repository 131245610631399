const projects = [
  {
    title: "Database (College project)",
    description:
      "Database management systems project made with wordpress. Final implementation only contains the resulting files",
    website: "https://github.com/PaulAlv99/ProjSGBD",
  },
  {
    title: "Data Structure and algorithms (College project)",
    description:
      "Data Structure and algorithms project.(Car workshop simulation)",
    website: "https://github.com/PaulAlv99/OficinaEDA",
  },
  {
    title: "Web devolopment (College project)",
    description:
      "House purchase and sale project with implementation of a live chat, comments in houses and user authentication.",
    website: "https://github.com/PaulAlv99/ProjetoDBW",
  },
  {
    title: "OOP-football simulation (College project)",
    description:
      "OOP-football simulation of a journey in a league for simplicity reasons, names of teams and players, as overall is random generated.",
    website: "https://github.com/PaulAlv99/POO-Project",
  },
];

export default projects;
