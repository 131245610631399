import React from "react";
import { useParams } from "react-router-dom";
import publicacoesLista from "./publicacoesLista";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import ErrorPage from "../ErrorPage/ErrorPage";
export default function BlogPost() {
  const { id } = useParams();
  const post = publicacoesLista.find((p) => p.id === parseInt(id));

  if (!post) {
    return <ErrorPage />;
  }

  return (
    <>
      <header className="fixed z-10 right-0 left-0 min-h-[50px]">
        <Navbar />
      </header>
      <div className="max-w-4xl mx-auto mt-32">
        <div className="inline-block">
          <Link to={`/blog`}>
            <h4 className="text-blue-500 text-sm underline block mt-2 ml-10 mr-10">
              Back
            </h4>
          </Link>
        </div>
        <div className="bg-neutral-800 ml-10 mr-10 rounded-lg">
          <br />
          <h2 className="text-5xl mb-2 ml-4 mr-4">{post.title}</h2>
          <p className="text-sm ml-4 mr-4 mb-5">
            {new Date(post.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </p>
          <p className="mt-2 ml-4 mr-4">{post.fullText}</p>
          <br />
        </div>
      </div>
    </>
  );
}
