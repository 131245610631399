import Home from "./Home/Home";
import Blog from "./Blog/Blog";
import ImpossibleList from "./ImpossibleList/ImpossibleList";
import CV from "./CV/CV";
import ErrorPage from "./ErrorPage/ErrorPage";
import BlogPost from "./Blog/BlogPost";
import { createBrowserRouter } from "react-router-dom";
import React from "react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
    index: true,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/blog/:id",
    element: <BlogPost />,
  },
  {
    path: "/impossible-list",
    element: <ImpossibleList />,
  },
  {
    path: "/cv",
    element: <CV />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);
export default router;
