import Navbar from "../Navbar/Navbar";
import React from "react";

export default function ErrorPage() {
    return (
        <>
            <header className="fixed z-10 right-0 left-0 min-h-[50px]">
                <Navbar/>
            </header>
        <div className="flex justify-center justify-self-center mt-32">
        <div className="error-page space-y-6">
            <h1 className="text-2xl text-center">Oops!</h1>
            <p className="text-4xl text-red-500">Sorry, an unexpected error has occurred.</p>

        </div>
        </div>
            </>
    );
}